import React, { forwardRef, useCallback, useState } from "react";
import { IntlShape, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { components } from "react-select";
import AsyncSelect from "react-select/async";

import { AppState } from "shared/data/reducers";
import { loadAirportSuggestions } from "shared/lib/golCopy";
import { AirportSelectValue } from "shared/lib/golObjectTypes/SelectObjects";
import { formattedMessageParams } from "shared/messages";

const { hotelPlaceholder, noAirportsFound, fillInPlaces } = defineMessages({
  hotelPlaceholder: formattedMessageParams("HotelSelect.hotelPlaceholder"),
  noAirportsFound: formattedMessageParams("AirportSelect.noAirportsFound"),
  fillInPlaces: formattedMessageParams("HotelSelect.fillInPlaces"),
});

const TAB_KEY_CODE = 9;
const MIN_NUMBER_CHARACTERS_TO_START_SEARCH = 3;

interface Props {
  testId: string;
  idJumToAfterTab?: string;
  changeValue: (option: any) => void;
  defaultAirportSuggestions: AirportSelectValue[];
  oCountries: any;
  allowDefaultOptions: boolean;
  defaultValue?: AirportSelectValue;
  error?: any;
  intl: IntlShape;
  aSuggestions: any[];
  inputRef?: React.Ref<any>;
}

const CustomInput = forwardRef((inputProps: any, ref) => (
  <components.Input
    {...inputProps}
    ref={ref}
    data-cy={inputProps.selectProps.testId}
    className="r3"
    id={`airport-select-input-${inputProps.selectProps.testId}`}
  />
));

interface OptionProps {
  aSuggestions: any[];
  data: any;
  isFocused: boolean;
  isSelected: boolean;
  selectOption: (string) => void;
  oCountries: any;
  intl: IntlShape;
}

const OptionComponent = (props: OptionProps) => {
  let hasMoreAirports = false;
  if (props.aSuggestions.length !== 0) {
    const res = props.aSuggestions.find(
      (oSuggestion) => oSuggestion.Code === props.data.value
    );
    hasMoreAirports = res === undefined ? false : res.Parent.length;
  }
  return (
    <div
      role="button"
      className={`header-search-form-option ${
        props.isFocused || props.isSelected
          ? "header-search-form-option-selected"
          : ""
      }`}
      onClick={() => props.selectOption(props.data)}
    >
      {hasMoreAirports ? "└ " : ""}
      {props.data.label}
      {props.aSuggestions &&
      props.aSuggestions.length > 0 &&
      props.aSuggestions.filter(
        (oSuggestion) =>
          oSuggestion.Parent !== "" && oSuggestion.Parent === props.data.value
      ).length > 0
        ? ``
        : ""}
      {props.oCountries && props.oCountries[props.data.Country]
        ? `, ${props.oCountries[props.data.Country]}`
        : ""}{" "}
      <b>({props.data.value})</b>
    </div>
  );
};

const HotelSelect = (props: Props) => {
  const {
    testId,
    idJumToAfterTab,
    changeValue,
    defaultAirportSuggestions,
    oCountries,
    allowDefaultOptions,
    defaultValue,
    error,
    intl,
    inputRef,
  } = props;

  const [noOptionsMessage, setNoOptionsMessage] = useState(false);
  const [airportSuggestions, setAirportSuggestions] = useState([]);

  const formatCityLabel = useCallback((label) => {
    if (!label) {
      return "";
    }

    let newLabel = "";

    const cities = label.split("/");
    cities.forEach((city, index) => {
      const cityName = city.split("-");
      if (cities.length > 0 && index !== cities.length - 1) {
        newLabel += `${cityName[0]}/`;
      } else if (cities.length > 0 && index === cities.length - 1) {
        newLabel += cityName[0];
      } else {
        newLabel = label;
      }
    });

    return newLabel;
  }, []);

  const onInput = useCallback(
    async (inputValue) => {
      if (inputValue.length < MIN_NUMBER_CHARACTERS_TO_START_SEARCH) {
        setNoOptionsMessage(false);
        return [];
      }

      const suggestions = await loadAirportSuggestions(inputValue);

      setNoOptionsMessage(true);
      setAirportSuggestions([...suggestions]);

      return suggestions.map((oSuggestion) => ({
        value: oSuggestion.Code,
        label: oSuggestion.$t,
        Country: oSuggestion.Country,
      }));
    },
    [setNoOptionsMessage, setAirportSuggestions]
  );

  const SingleValue = useCallback(
    (singleValueProps) => {
      const label = formatCityLabel(singleValueProps.data.label);

      if (!label) {
        return null;
      }

      return (
        <span
          role="button"
          onClick={() => singleValueProps.clearValue()}
          id={`${testId}-value`}
        >
          <span className="header-search-form-inner-field-value">{label}</span>{" "}
          <span className="header-search-form-inner-field-additional">
            {` (${singleValueProps.data.value})`}
          </span>
        </span>
      );
    },
    [formatCityLabel, testId]
  );

  const SelectContainer = useCallback(
    (containerProps) => (
      <components.SelectContainer
        {...containerProps}
        className="react-select-2-wrapper-2"
      />
    ),
    []
  );

  const customMenuContainer = useCallback(
    (menuProps) => {
      if (
        menuProps.selectProps.inputValue.length === 0 &&
        !allowDefaultOptions
      ) {
        return null;
      }

      return <components.Menu {...menuProps} className="select-menu-outer" />;
    },
    [allowDefaultOptions]
  );

  const onChange = useCallback(
    (option) => {
      if (option !== undefined && option !== null) {
        changeValue(option);
      } else {
        changeValue(null);
      }
    },
    [changeValue]
  );

  const onKeyDown = useCallback(
    (e) => {
      if (!idJumToAfterTab) return;

      if (e.keyCode === TAB_KEY_CODE) {
        document
          .getElementById(`airport-select-input-${idJumToAfterTab}`)
          ?.focus();
      }
    },
    [idJumToAfterTab]
  );

  const Option = useCallback(
    (val) => {
      const suggestions =
        airportSuggestions.length === 0
          ? defaultAirportSuggestions
          : airportSuggestions;

      return (
        <OptionComponent
          oCountries={oCountries}
          {...val}
          aSuggestions={suggestions}
          className="r5"
          intl={intl}
        />
      );
    },
    [oCountries, airportSuggestions, defaultAirportSuggestions, intl]
  );

  const Placeholder = useCallback((placeholderProps) => {
    if (placeholderProps.isFocused) return null;
    return (
      <components.Placeholder
        {...placeholderProps}
        className="react-select-2-placeholder"
      />
    );
  }, []);

  return (
    <div className="header-search-form-results">
      <AsyncSelect
        onKeyDown={onKeyDown}
        ref={inputRef}
        placeholder={intl.formatMessage(hotelPlaceholder)}
        testId={testId}
        className="react-select-2-wrapper"
        classNamePrefix="react-select-hotels"
        styles={{
          control: () => ({}),
          valueContainer: (base) => ({
            ...base,
            paddingLeft: 0,
            marginLeft: 0,
            paddingTop: 0,
            marginTop: -4,
          }),
          placeholder: (base) => ({
            ...base,
            marginTop: -1,
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: "200px",
            paddingTop: 0,
            paddingBottom: 0,
          }),
          noOptionsMessage: () => ({
            marginLeft: "15px",
            fontFamily: "Muli",
            padding: "10px 0px 10px 0px",
            display: !noOptionsMessage ? "none" : "auto",
          }),
        }}
        isClearable
        value={defaultValue}
        noOptionsMessage={() => intl.formatMessage(noAirportsFound)}
        components={{
          DropdownIndicator: () => null,
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
          SingleValue,
          Menu: customMenuContainer,
          SelectContainer,
          Input: CustomInput,
          Option,
          Placeholder,
        }}
        onChange={onChange}
        loadOptions={onInput}
        defaultOptions={
          allowDefaultOptions
            ? defaultAirportSuggestions.map((oSuggestion) => ({
                value: oSuggestion.value,
                label: oSuggestion.label,
                Country: oSuggestion.Country,
              }))
            : null
        }
        cacheOptions
      />
      {error ? (
        <div className="search-field-error">
          {intl.formatMessage(fillInPlaces)}
        </div>
      ) : null}
    </div>
  );
};

export default connect((state: AppState) => ({
  oCountries: state.storage.countries,
  defaultAirportSuggestions: state.storage.defaultAirportSuggestions,
}))(HotelSelect);
