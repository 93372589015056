/**
 * Sentry configuration
 * This file contains configuration for Sentry error tracking
 */

/**
 * List of errors to ignore in Sentry reporting
 * Add patterns for errors that should not be reported to Sentry
 */
export const SENTRY_IGNORE_ERRORS = [
  // Internal library errors
  "_abt/*init*/(arg[0]='isArray')",
  // Client script errors (not from our app)
  "TypeError: Cannot read properties of null (reading 'contentWindow')",
];
