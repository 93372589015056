global.appCache = global.appCache || {};

function clearAirlinesInAppCache() {
  Object.keys(global.appCache).forEach((key) => {
    if (key.startsWith("airlines")) {
      delete global.appCache[key];
    }
  });
}

function clearCountriesInAppCache() {
  Object.keys(global.appCache).forEach((key) => {
    if (key.startsWith("countries")) {
      delete global.appCache[key];
    }
  });
}

module.exports = {
  appCache: global.appCache,
  clearAirlinesCache: clearAirlinesInAppCache,
  clearCountriesCache: clearCountriesInAppCache,
};
